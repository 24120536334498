<template>
  <div class="container">

    <!--Breadcrumb 영역 s-->
    <div class="top-nav-container">
      <nav role="navigator">
        <ul class="breadcrumbs list-unstyled hide-sm">
          <li class="breadcrumb">제품</li>
          <li class="breadcrumb" v-html="getHead" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb" v-html="getTitle" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb">관련사이트</li>
        </ul>
      </nav>
    </div>
    <!--Breadcrumb 영역 e-->

    <div class="section">

      <!--Page Title 영역 s-->
      <div class="tit_page_area">
        <h1>관련 사이트</h1>
        <p>BMS 제품 관련 주요사이트 관련 정보 및 링크를 제공합니다.</p>
        <!--<div class="tit_right_icon_area">
            <a href="./product_brochure_listview.html">
                <img src="@/assets/images/icon_listview.png" alt="">
            </a>
        </div>-->
      </div>
      <!--Page Title 영역 e-->

      <div v-if="html" v-html="html" @click="check($event)"/>
      <div class="b_lists" v-else>
        <div class="nolist">
          <img src="@/assets/images/icon_alert.png" alt="">
          <p>컨텐츠 <span>준비중</span>입니다.</p>
          <p>이용에 불편을 드려서 죄송합니다.<br>
            빠른 시일내에 컨텐츠를 준비하도록 하겠습니다.</p>
        </div>
      </div>
      <div class="modal" v-if="showModal">
        <!-- 사이트 이탈 Modal의 내용 -->
        <div class="modal_contents">
          <div class="modal_close close">
            <a @click="closeModal">close</a>
          </div>
          <p class="modal_tit"><strong>BMSON</strong>웹사이트가 아닌 외부사이트로 이동합니다.</p>
          <p class="modal_exp">현재 이용 중이신 BMSON 웹사이트를 벗어나 다른 외부 웹사이트로 이동합니다. BMS는 BMSON 사이트 외 외부 사이트의
            콘텐츠에 대한 책임을 지지 않으며, 해당 콘텐츠로 인한 손해나 손상에 대한 법적 책임이 없습니다. 외부 사이트 링크는 단지 BMSON 웹사이트
            사용자들에게 편의를 제공하기 위한 목적으로 제공합니다.</p>
          <div class="modal_btn">
            <span class="btn_modal" @click="closeModal({url: url})">계속하기</span>
            <span class="btn_modal" @click="closeModal">취소</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "ExternalLink",
  props: {},
  data() {
    return {
      html: '',
      showModal: false,
      urlType: '',
      url: '',
    }
  },
  computed: {
    getLandingPath() {
      return {
        name: 'Product',
        params: {productName: this.$route.params.productName},
      }
    },
    getHead() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].head
    },
    getTitle() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].title
    },
    getEnv() {
      return process.env.VUE_APP_HTML_SUFFIX
    }
  },
  methods: {
    check(event) {
      let {target} = event
      this.findATag(target, event)
    },
    findATag(element, event) {
      if(!element.parentNode) {
        event.preventDefault()
        return false;
      } else if(element.tagName.toLowerCase() !== 'a'){
        return this.findATag(element.parentNode, event)
      } else {
        let {href, origin: targetOrigin} = element
        let {origin: locationOrigin} = location
        if (href) {
          let expUrl = /^http[s]?:\/\//i;
          if (expUrl.test(href)) {
            if (targetOrigin !== locationOrigin) {
              this.openExternalModal(element.href)
            } else {
              return true;
            }
          }
        }
        event.preventDefault()
      }
    },
    openExternalModal(url) {
      this.url = url
      this.showModal = true;
      document.documentElement.classList.add("overflow-y-hidden");
    },

    closeModal({url}) {
      if (url) {
        window.open(url, '_blank')
      }
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
    },
  },
  watch: {
    $route() {
      axios.get(this.$store.state.menu.productMenu[this.$route.params.productName].externalLink)
          .then(({data}) => this.html = data)
          .catch(() => this.html = '')
    }
  },
  created() {
    axios.get(this.$store.state.menu.productMenu[this.$route.params.productName].externalLink)
        .then(({data}) => this.html = data)
        .catch(() => this.html = '')
    this.$emit('set-init-tab')
  },

}

</script>

<style scoped>
.extenal_link > img:hover {
  cursor: pointer;
}
</style>
